import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { useTranslation } from "react-i18next";
import { ScrollArea } from "./ui/scroll-area";

const Disclaimer = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <Dialog>
        <DialogTrigger> <span className="underline text-sm">{t("components.loginCard.notice")}</span></DialogTrigger>
        <DialogContent className="max-w-5xl">
          <DialogHeader>
            <DialogTitle>Disclaimer</DialogTitle>
          </DialogHeader>

          <ScrollArea className="flex  flex-col gap-4 max-h-screen overflow-hidden">
            <div className="flex flex-col gap-4">
              <p>
                Level2 is an automated financial market analysis
                service.
              </p>
              <ul className="flex flex-col gap-4">
                <li>
                  There are potential risks relating to investing and trading.
                  You must be aware of such risks and familiarise yourself in
                  regard to such risks and seek independent advice relating
                  thereto. You should not trade with money that you cannot
                  afford to lose.
                </li>
                <li>
                  The Level2 service and its content should not be
                  construed as a solicitation to invest and/or trade. You should
                  seek independent advice in this regard.
                </li>
                <li>
                  Past performance is not indicative of future performance. No
                  representation is being made that any results discussed within
                  the service and its related media content will be achieved.
                </li>
                <li>
                  All opinions, news, research, analyses, prices or other
                  information is provided as general market commentary and not
                  as investment advice. Level2, their members,
                  shareholders, employees, agents, representatives and resellers
                  do not warrant the completeness, accuracy or timeliness of the
                  information supplied, and they shall not be liable for any
                  loss or damages, consequential or otherwise, which may arise
                  from the use or reliance of the Level2 service and
                  its content.
                </li>
              </ul>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Disclaimer;
