import { Loader2 } from "lucide-react";
import React, { useEffect, useState } from "react";

interface ILoaderProps {
  text?: string;
}

export default function Loader(props: ILoaderProps) {
  if (props.text)
    return (
      <div className="flex flex-col items-center gap-1 justify-center">
        <Loader2 className="h-5 w-5 animate-spin" />
        <span className="">{props.text}</span>
      </div>
    );

  return <Loader2 className="h-5 w-5 animate-spin" />;
}
