import axios from "axios";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { authFormState } from "../../Atoms/auth/AuthFormState";
import { API_URL } from "../../config";
import Loader from "../Loader";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Loader2, EyeOff, Eye } from "lucide-react";
interface IFormValues {
  email: string;
  otp: number;
  new_password: string;
}

type ResetPasswordProps = {
  toast?: any;
};

const ResetPassword: React.FC<ResetPasswordProps> = (
  props: ResetPasswordProps
) => {
  // router declearation
  const router = useRouter();
  const { t } = useTranslation("common");
  // global state manipulation
  const setFormState = useSetRecoilState(authFormState);
  const [showChangePasswordFields, setShowChangePasswordFields] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IFormValues>();

  // handling the from submit button
  const onSubmit = async (formData: any) => {
    setLoading(true);
    var url = new URL("send_otp_password_reset", API_URL);
    url.searchParams.append("email", formData.email as string);
    const res = await axios.post(url.toString(), {});
    const data = await res;
    if (data.status == 200) {
      setShowChangePasswordFields(true);
    }
    props.toast(true, data.data.message);

    setLoading(false);
    //data.message
  };

  const onSubmitOTP = async (formData: any) => {
    setLoading(true);
    try {
      var url = new URL("reset_password", API_URL);
      url.searchParams.append("email", formData.email as string);
      url.searchParams.append("new_password", formData.new_password as string);
      url.searchParams.append("otp", formData.otp as string);
      const res = await axios.post(url.toString(), {});
      const data = await res;
      if (data.status == 200) {
        setFormState({ view: "login" });
        props.toast(true, data.data.message);
      }
    } catch (e: any) {
      props.toast(false, e.response.data.detail);
      setLoading(false);
    }
    setLoading(false);
    //data.message
  };

  const resendOTP = async () => {
    if (loading == true) {
      return false;
    }
    setLoading(true);
    var url = new URL("send_otp_password_reset", API_URL);
    url.searchParams.append("email", getValues("email") as string);
    const res = await axios.post(url.toString(), {});
    const data = await res;
    setLoading(false);
    props.toast(true, data.data.message);
  };

  return (
    <>
      <div className="max-w-[500px]">
        {showChangePasswordFields == false && (
          <form
            action=""
            method="post"
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-5"
          >
            <div className="text-center">
              <Input
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t("components.resetPassword.validEmail"),
                  },
                })}
                type="email"
                placeholder={t("Enter registered email address")}
                name="email"
                autoComplete="off"
              />
              {errors?.email && (
                <div className="danger">
                  <div className="text-red-foreground text-center mt-3 ">
                    {errors.email?.type === "required" && (
                      <p>{t("components.resetPassword.emailRequired")}</p>
                    )}
                    {errors.email?.type === "pattern" && (
                      <p>{errors.email?.message}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="text-center flex gap-4 justify-center">
              <Button type="button" variant={"outline"} onClick={() => setFormState({ view: "login" })}>
                {t("Cancel")}
              </Button>
              <Button type="submit">
                {t("components.resetPassword.submit")}
                {loading ? (
                  <Loader2 className="ml-2 h-5 w-5 animate-spin" />
                ) : (
                  ""
                )}
              </Button>
            </div>
          </form>
        )}
        {showChangePasswordFields == true && (
          <form
            action=""
            method="post"
            onSubmit={handleSubmit(onSubmitOTP)}
            className="flex flex-col space-y-4 resetForm"
          >
            <div className="text-left">
              <p className=" mb-5 flex flex-row justify-between text-primary ">
                <span></span>
                <span
                  className="cursor-pointer text-muted-foreground text-sm select-none underline underline-offset-4 hover:text-primary"
                  onClick={() => {
                    resendOTP();
                  }}
                >
                  {t("components.resetPassword.resendOtp")}
                </span>
              </p>
              <Input
                {...register("otp", { required: true })}
                type="text"
                placeholder={t("OTP received in your email")}
                name="otp"
                autoComplete="off"
              />
              {errors?.otp && (
                <div className="danger">
                  <div className="text-red-foreground text-center mt-3 ">
                    {errors.otp?.type === "required" && (
                      <p>{t("components.resetPassword.otpRequired")}</p>
                    )}
                    {errors.otp?.type === "pattern" && (
                      <p>{errors.otp?.message}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="text-left">
              <Input
                {...register("new_password", {
                  required: true,
                  pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                })}
                type="password"
                placeholder={t("Your new password")}
                name="new_password"
                autoComplete="off"
              />
              {errors?.new_password && (
                <div className="danger">
                  <div className="text-red-foreground text-center mt-3 ">
                    {errors.new_password?.type === "required" && (
                      <p>{t("components.resetPassword.newPasswordRequired")}</p>
                    )}
                    {errors.new_password?.type === "pattern" && (
                      <p>{t("components.resetPassword.passwordError")}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="text-center">
              {loading ? (
                <Loader />
              ) : (
                <Button type="submit">
                  {t("components.resetPassword.submit")}
                </Button>
              )}
            </div>
          </form>
        )}
      </div>
      <div className="space-y-4">
        <div className="text-center text-sm mt-5 text-muted-foreground">
          <div>
            <span className="">
              {t("components.resetPassword.loginClick")}{" "}
            </span>
            <span
              className="cursor-pointer select-none underline underline-offset-4 hover:text-primary"
              onClick={() => setFormState({ view: "login" })}
            >
              {t("components.resetPassword.here")}
            </span>
          </div>
          <div>
            <span className="">{t("components.resetPassword.click")}</span>{" "}
            <span
              className="cursor-pointer select-none underline underline-offset-4 hover:text-primary"
              onClick={() => router.push("https://mktdynamics.com/")}
            >
              {t("components.resetPassword.here")}
            </span>{" "}
            <span className="">
              {t("components.resetPassword.backToWebsite")}
            </span>
          </div>
        </div>
        <hr className="border-primary" />
        <div className="text-center text-sm text-muted-foreground">
          <span>{t("components.troubleLogging")}</span>
          <span
            className="cursor-pointer select-none underline underline-offset-4 hover:text-primary"
            onClick={() => {
              window.location.href =
                "mailto:contact@trylevel2.com?subject=Request%20Assistance&body=Hi%20Team,%0DI%20need%20assistance.%20My%20contact%20email%20is%20{enter%20your%20email%20address}%0D%0D%0D%0D";
            }}
          >
            {t("components.contactMail")}
          </span>
        </div>
      </div>
    </>
  );
};
export default ResetPassword;
